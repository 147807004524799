import axiosIns from '@/libs/axios.js';

class PaymentService {
    store(data){
        return axiosIns.post(`store-payment`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    listing(pageNumber=1, course = null, search = null) {
        return axiosIns.get('payment-listing?page='+pageNumber+'&course='+course+'&search='+search).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    monthlyListing() {
        return axiosIns.get(`monthly-payment-listing`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    update(data, id){
        return axiosIns.post(`update-payment/${id}`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    find(id){
        return axiosIns.get(`find-payment/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    delete(id){
        return axiosIns.post(`delete-payment/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new PaymentService();